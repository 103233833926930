<!--6th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                >Authorization Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Authorization Form</li>
            </ol>
        </div>
    </div>
    <br />
    <br /><div style="margin-top:60px"></div>
          <div id="pdf-content">
              <div class="container pt-3 content">
                  <div class="container">
                      <div class="row no-gap">
                          <div class="col-lg-12">
                              <h2 class="surr">
                                  <b>AUTHORIZATION LETTER</b>
                              </h2>
                              <br />
                              <br />
                          </div>

                          <div class="col-lg-12 col-4 border-top1 border-top2">
                              <br/>
                              <div class="row">
                                  <div class="col-9"></div>
                                  <div class="col-3">
                                      <p style="font-size:13px">Date :<span style="color:red;">{{todayDate}}</span><strong class="text-danger"></strong></p>
                                  </div>
                              </div>
                               <br />
                              <h5>Ref No.<span style="color:red;">{{Auth}}</span><strong class="text-danger"></strong></h5>


                              <div class="col-12 text-start">
                                  <p><span style="color:red;">{{loginDet[0].CompanyName}},{{loginDet[0].CompanyAddress}}</span><br /></p>
                              </div>
                              <p>To,</p>
                              <p>
                                  <span style="color:red;">
                                      {{Fdata[0].SecretaryName}},{{Fdata[0].SecretaryAddress}}
                                  </span>
                              </p>
                              <p>
                                  <b>Sub:</b> Issue of fresh RC
                              </p>
                              <p><span style="color:red;"> {{Fdata[0].VehicleRegNo}}</span>. </p>
                              <p>
                                  Respected Sir,
                              </p>
                          </div>
                      </div><br />
                      <div class="main-content">
                          <p class="para">
                              I am the proprietor of M/s <span style="color:red;">{{loginDet[0].CompanyName}},{{loginDet[0].CompanyAddress}}</span>.
                              We have applied for fresh RC for the above mentioned vehicle no as the owner is absconding. Hence we request you to issue FRC in
                              favor of financier.
                          </p>
                          <p class="para">
                              The vehicle is under my possession, parked at
                              <span style="color:red;">
                                  {{Fdata[0].ParkedAt}}
                              </span>. During inspection the vehicle will be
                              produced. We request that Mr.<span style="color:red;">
                                  {{loginDet[0].Name}}
                              </span> our outstanding
                              field worker, be authorized to coordinate with you on
                              our behalf to complete of this task.
                          </p>
                      </div><br />
                      <div class="row">
                          <div class="col-lg-9">
                              <div class="text-center">
                                  <p class="para">
                                      Thank you,
                                  </p>
                                  <div *ngIf="qrCodeDataUrl">
                                      <img [src]="qrCodeDataUrl" alt="QR Code" style="width: 36%;" />
                                  </div>
                                  <p style="font-size:15px">Note:Toverify the authenticityof Form-36 refer to Scan</p>
                              </div>
                          </div>

                          <div class="col-lg-3 ">

                              <p style="font-size:16px">Yours faithfully</p>

                          </div>

                      </div>

                  </div>


              </div>
              
              
            
              <div class="container" style="margin-top:5px" >
                  <div class="col-lg-12 col-4 border-top1 border-top2" >

                  </div>
              </div>
          </div>
    <div class="row">
        <div class="col-lg-8 ">
        </div>
        <div class="col-lg-1 ">
            <button (click)="navigateTOeditform(Fdata[0].RefNo)" class="pdf"><i class="fa fa-edit"></i> Back  </button>
        </div>
        <div class="col-lg-2  ">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
        </div>


    </div>
