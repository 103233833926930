<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
               
            </div>
            <div class="col-sm-4 col-md-4 col-4">
                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
            </div>
            <div class="col-sm-4 col-md-4 col-4 ">
                    <div class="middle">
                        <h6> <b>BY WAY OF SPEED POST</b></h6>
                    </div>
                    <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
            </div>
                <div class="col-sm-4 col-md-4 col-4 text-Center right no-gap">
                    <b>
                        <p class="text-black ">VVS. SATYANARAYANA</p>
                        <p class="text-black ">Dr.L.Lakshmi</p>
                        <p class="text-black ">Advocates</p>
                        <p class="text-black ">Legal Consultants & Solicitors</p>
                        <p class="text-black ">Cell No.9849005215</p>
                    </b>
                </div>
            </div>
    </div>
    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container  bottom-border">
            <div class="row no-gap">
                <div class="col-lg-12   border-top1"></div>
                <div class="col-12 text-end border-top2">
                    <p>Date: <b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                </div>
                <div class="col-lg-4 col-4">
                    <p>To</p>
                    <p><b>{{Fdata[0].BorrowerName}}</b></p>
                    <p><b>{{Fdata[0].BarrowerAddress}}</b></p>
                    <p>(Borrower / Debtor)</p>
                </div>
                <div class="col-lg-3 col-4">

                </div>
                <div class="col-lg-5 col-4">
                    <div class="fromadd">
                        <p>To</p>
                        <p><b>{{Fdata[0].SuretyName}}</b></p>
                        <p><b>{{Fdata[0].SuretyAddress}}</b></p>
                        <p>(Surety / Guarantor)</p>
                    </div>
                </div>
            </div><br />
            <div class="main-content">
                <h5>Ref:<strong class="text-danger">{{rolename}}</strong></h5>
                <p class="para">
                    Invitation to Conciliation as per provisions of Section 62 of the Arbitration and Condition Act, 1996/ Your Loan Account/Agreement No, <span class="text-danger">{{ Fdata[0].AgreementNo }}</span> Vehicle No <span class="text-danger"> {{Fdata[0].VehicleRegNo}}</span> Madam/Sir, Under instructions from and on behalf of my client M/s. <span class="text-danger"> {{ Fdata[0].BorrowerName }}</span>
                    having their registered office at <span class="text-danger">{{ Fdata[0].BarrowerAddress }} </span>and we address you as under: You had availed a Loan Rs. <span class="text-danger">{{ Fdata[0].LoanAmount }}</span> from our client vide Loan Account/Agreement No. <span class="text-danger"> {{ Fdata[0].AgreementNo }}</span> Vehicle Regn.No <span class="text-danger"> {{Fdata[0].VehicleRegNo}}</span> and were accordingly liable
                    to make repayment of the said loan along with interest and other charges. You have , however , failed / avoided the terms of the Agreement and your account is overdue as on <span class="text-danger"> {{ Fdata[0].AuctionedDate | date:'dd-MM-yyyy' }}</span> A sum of Rs. <span class="text-danger"> {{ Fdata[0].LoanAmount }}/-</span> is due and payable by you, to our client
                    along with Legal Charges and Other Expenses. Our client is therefore, in the process of instilling legal proceedings, for the recovery of the aforementioned amount, as well as interest thereupon and other charges.
                </p>
                <p class="para">However, before initiating any legal action, our client intends to explore the possibility of a settlement, through the process of Conciliation and accordingly intends to refer the dispute to VVS. Satyanarayana, Dr. L. Lakshmi Advocate who would act as a Conciliator, for an amicable settlement. In case you are also interested in settling the matter, amicably, by the process of Conciliation, as aforementioned you are called upon to attend the Conciliation Camp being organized at <span class="text-danger">{{ Fdata[0].BarrowerAddress }}</span>, on or before <span class="text-danger"> {{ Fdata[0].AuctionedDate | date:'dd-MM-yyyy'}}</span> at 11 am to 5 pm. In case, however, you fail/avoid to come forward for Conciliation, on the said date, the present invitation shall be deemed to have lapsed/rejected and in such event, our client shall be constrained to initiate appropriate legal steps, civil and criminal, within 15 days against you.</p>
            </div>
            <p> <b>We will send notice and you have to pay charges of Rs. 1000/- for the notice.</b></p>
            <div class="row">
                <div class="col-lg-8">
                    <!--<div *ngIf="qrCodeDataUrl">
            <img [src]="qrCodeDataUrl" alt="QR Code" />
        </div>-->
                </div>
                <div id="pdf-content">
                    <div class="row">
                        <div class="col-lg-8"></div>
                        <div class="col-lg-4">
                            <div class="text-center signature">
                                <p>Yours sincerely,</p><br />
                                <p class="name"><b>VVS SATYA NARAYANA</b></p>
                                <p class="name">Advocate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-Center">
                    <h6 class="text-danger boldd p-3">
                        Address to corresponding:{{loginDet[0].CompanyAddress}}
                    </h6>
                </div>
              
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-8 ">
           </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].AgreementNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-2  ">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
    </div>


</div>
