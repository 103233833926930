import { Component, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralserviceService } from '../../generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;
    public form: FormGroup;
    loginForm: FormGroup;
    HomeUrl: any;
    passwordshow: boolean = false; // Property to toggle password visibility
    public EMailID: any;
    public PWD: AbstractControl;
    passwordShow: boolean = false;
    confirmPasswordShow: boolean = false;
    Pwd: any;
    OTP: any;
    Confirmpassword: any;
    randomnumber: any;
    Phone1: any;
    CheckBox: string;
    Remember: boolean;

    constructor(
        private fb: FormBuilder,private router: Router,private generalService: GeneralserviceService,
        private http: HttpClient, private elementRef: ElementRef, private renderer: Renderer2,
        private cookieService: CookieService
    ) {
        // Initialize the form using FormBuilder
        this.loginForm = this.fb.group({
            'Mobile/EMail': ['', [Validators.required]],
            Password: ['', [Validators.required]],  // Password is required
        });

        // Fetch the WebService URL
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.form = this.fb.group({
            Phone1: ['',],
            Password: ['',],
            email: ['',],
        })
    }

    // Method to toggle password visibility
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    // Login method to authenticate the user
    login() {
        debugger;
        if (this.loginForm.valid) {
            const loginData = new FormData();

            loginData.append("Param1", this.loginForm.value['Mobile/EMail']);
            loginData.append("Param2", this.loginForm.value.Password);

            const url = "api/FinancierLaw/Roles_Login";

            this.generalService.PostData2(url, loginData).then(
                (data: any) => {
                    console.log('API Response:', data);  // Log the API response

                    // Adjust the condition based on the actual API response structure
                    if (data && data !== 'Invalid User') {
                        localStorage.setItem("LoginDetails", JSON.stringify(data));

                        // Navigate to the home page and reload
                        this.router.navigate(['/1home']).then(() => {
                            window.location.reload(); // Reload the page after navigation
                        });
                    } else {
                        alert("Invalid Mobile/Email or password.");
                        //this.router.navigate(['/RoleSelection'])
                    }
                },
                (error) => {
                    alert("Invalid Mobile/Email or password.");
                }
            );
        } else {
            alert("Invalid Mobile/Email or password.");
        }
    }

    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.Phone1);
            this.cookieService.set('password', this.form.value.Password);
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }

    //ForgotPassword() {
    //    debugger
    //    this.EMailID = "";
    //    this.OTP = "";
    //    this.Pwd = "";
    //    this.Confirmpassword = "";
    //    const modal = this.elementRef.nativeElement.querySelector('#myModal2');
    //    this.renderer.setStyle(modal, 'display', 'block');
    //    this.CheckMobile(this.EMailID);
    //}

    //CheckMobile(EMailID) {
    //    debugger;

    //    EMailID: EMailID

    //    if (EMailID == undefined || EMailID == "" || EMailID == null) {
    //        debugger;
    //        //alert("Please Enter EmailID")
    //        // this.openToast();
    //    }
    //    else {

    //        var UploadFile = new FormData();
    //        UploadFile.append("Mobile", EMailID);
    //        //UploadFile.append("Flag", "1");
    //        var url = "api/FinancierLaw/checking_Mobile"
    //        debugger;
    //        this.generalService.PostData(url, UploadFile).then(data => {
    //            debugger;
    //            if (data != 'NOTEXIST') {
    //                debugger
    //                this.EMailID = data[0].EMailID;
    //                this.OTP = ""
    //                this.Phone1 = EMailID;
    //                this.SendOtpForMobile();
    //                const modal2 = this.elementRef.nativeElement.querySelector('#myModal2');
    //                this.renderer.setStyle(modal2, 'display', 'none');

    //                // Show myModal3
    //                const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
    //                this.renderer.setStyle(modal3, 'display', 'block');

    //            }
    //            else {

    //                alert("Please Enter Valid Mobile Number")
    //            }
    //        }, err => {
    //        });
    //    }

    //}

    //SendOtpForMobile() {
    //    debugger
    //    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    //    var url = 'api/Azole/SendOTPtoMail?EmailId=' + this.EMailID + '&OTP=' + this.randomnumber;
    //    this.generalService.GetData(url).then(data => {
    //        if (data == "SUCCESS") {

    //        }
    //        else {
    //        }
    //    });
    //}

    //SendOTPtoMail() {

    //    var Content = "Your OTP is: " + this.OTP;
    //    var UploadFile = new FormData();
    //    UploadFile.append("EMailID", this.EMailID);
    //    UploadFile.append("Content", Content);
    //    var url = "api/Azole/SendOTPtoMail";
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {

    //    })
    //}
    /*end code*/

    /*verifyotp for forgotpassword*/
    //VerifyOtp(otp) {
    //    debugger
    //    if (this.randomnumber == otp) {
    //        const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
    //        this.renderer.setStyle(modal3, 'display', 'none');

    //        const modal = this.elementRef.nativeElement.querySelector('#myModal4');
    //        this.renderer.setStyle(modal, 'display', 'block');
    //    }
    //    else {
    //        alert("Invalid OTP");
    //    }
    //}
    /*end region*/



    /*Change password*/
    //ChangePassword(PWD, confirmpassword) {
    //    if (PWD == undefined || PWD == null || PWD == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
    //        alert("Please enter password");
    //    }
    //    else {
    //        if (PWD != confirmpassword) {
    //            alert("password and confirmpassword must be same");
    //        }
    //        else {
    //            var UploadFile = new FormData();
    //            UploadFile.append("Password", PWD);
    //            UploadFile.append("Mobile", this.EMailID);
    //            var url = "api/Azole/Forgot_Password"
    //            debugger;
    //            this.generalService.PostData(url, UploadFile).then(data => {
    //                debugger;
    //                if (data == 'SUCCESS') {
    //                    this.generalService.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Change Password Successfully.', '')
    //                    const modal = this.elementRef.nativeElement.querySelector('#myModal4');
    //                    this.renderer.setStyle(modal, 'display', 'none');
    //                }
    //            }, err => {
    //                const modal = this.elementRef.nativeElement.querySelector('#myModal4');
    //                this.renderer.setStyle(modal, 'display', 'none');
    //                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    //            });
    //        }
    //    }
    //}
}
