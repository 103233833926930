import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-form36',

  templateUrl: './form36.component.html',
  styleUrl: './form36.component.scss'
})
export class Form36Component {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any;
    form36: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.form36 = localStorage.getItem("36form");

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            BarrowerAddress: ['', Validators.required],
          BorrowerName: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
          Manufacture: ['', Validators.required],
            Model: ['', Validators.required],
            RegisteringAuthority: ['', Validators.required],
            Fee_FC: ['', Validators.required],
           
        });

    }


    ngOnInit() {
      

        this.Getforms();
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.form36);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                const auctionedDate = this.formatDate(this.Fdata[0].AuctionedDate);
                this.RegisterForm.patchValue({
                    RegisteringAuthority: this.Fdata[0].RegisteringAuthority,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    BorrowerName: this.Fdata[0].BorrowerName,
                    BarrowerAddress: this.Fdata[0].BarrowerAddress,
                    Manufacture: this.Fdata[0].Manufacture,
                    Fee_FC: this.Fdata[0].Fee_FC,
               
   
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;

        if (!value.BorrowerName || !value.BarrowerAddress || !value.VehicleRegNo || !value.RegisteringAuthority || !value.Fee_FC) {
            alert("Please fill all the mandatory fields: BorrowerName, BarrowerAddress, Vehicle Reg Number,Registering Authority,Fee_FC");
            return; // Exit the function if validation fails
        }
        this.arr = [];

        var arr = [{
            // Name: value.Name,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            Manufacture: value.Manufacture,
            Model: value.Model,
            RegisteringAuthority: value.RegisteringAuthority,
             NoticeID:6,
            Fee_FC: value.Fee_FC,
            VehicleNo: value.VehicleNo,
            RegID: this.loginDet[0].RegID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('form6', data);
                //  this.router.navigate(['/FinacialformPDF']);
                this.router.navigate(['/final36']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            NoticeFormID: this.Fdata[0].NoticeFormID,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            Manufacture: value.Manufacture,
            Model: value.Model,
            RegisteringAuthority: value.RegisteringAuthority,
            NoticeID: 6,
            Fee_FC: value.Fee_FC,
            VehicleNo: value.VehicleNo,
            RegID: this.loginDet[0].RegID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form Updated successfully");
                localStorage.setItem('form6', data);
                this.router.navigate(['/final36']);
                localStorage.removeItem('36form');

            }
        },

        );
    }



}
