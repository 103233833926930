import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;

    @ViewChild('dragImage', { static: true }) dragImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('zoomImage', { static: true }) zoomImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('magnifier', { static: true }) magnifierElement!: ElementRef<HTMLDivElement>;

    scale = 2; // Zoom scale
    isLoggedIn: any;
    subdata: any;
    approvedStatus: number | undefined;

    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.LoginDetails = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails);
    }

    ngOnInit() {
        this.subscription();
    }

    onConciliationClick() {
        if (!this.generalService.isRegistered()) {
            this.router.navigate(['/Registration']);
        } else if (!this.generalService.isLoggedIn()) {
            this.router.navigate(['/login']).then(() => {
                this.router.navigate(['/home']);
            });
        } else {
            this.router.navigate(['/home']);
        }
    }

    subscription() {
        debugger;

        this.arr = [];
        const arr = [{}];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '4');

        const url = 'api/FinancierLaw/FA_subscriptionCRUD';

     //   console.log('Sending data to API:', uploadfile);

        this.generalService.PostData2(url, uploadfile).then(
            (data: any) => {
                debugger;
                this.subdata = data;

                const loginRegID = this.LoginDetails[0].RegID;

                const filteredData = this.subdata.filter((item: any) => item.RegID === loginRegID);

                if (filteredData.length > 0) {
                    this.approvedStatus = filteredData[0].ApprovedStatus;
                  //  console.log('Approved Status:', this.approvedStatus);
                } else {
                    console.log('No matching RegID found.');
                    this.approvedStatus = undefined; // Ensure `approvedStatus` is set to undefined if no match
                }
            },
            error => {
               // console.error('API call failed:', error);
               // alert("Failed to sign in: An error occurred while processing your request.");
            }
        );
    }

    onCardClick(cardNumber: number): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform', cardNumber]);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
}
