<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Contact Us
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Contact Us</li>
            </ol>
        </div>
    </div>
    <div class="container">
        <div class="row">

            <!-- Contact Start -->
            <div class="container-xxl py-5">
                <div class="container">
                    <h3 class="mb-5">If You Have Any Query, Please Contact Us</h3>
                    <div class="row g-5">
                        <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="card">
                                <form>
                                    <div class="row g-3">
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="name" placeholder="Your Name">
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" id="email" placeholder="Your Email">
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="subject" placeholder="Subject">
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <textarea class="form-control" placeholder="Leave a message here" id="message" style="height: 250px"></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-secondary rounded-pill py-3 px-5" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="card" style="border: none;">
                                <h3 class="mb-4">Contact Details</h3>
                                <div class="row  pb-5 mb-3">
                                    <div class="col-lg-2">
                                        <i class="fa fa-map-marker-alt icons text-body"></i>
                                    </div>
                                    <div class="col-lg-10">
                                        <div class="ms-3">
                                            <h6 class="headd"><strong>Our Office</strong></h6>
                                            <span>Sri Colony, Kapra, Oppsite Malla Reddy Gardens, Secunderabad, 500062</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row  pb-5 mb-3">
                                    <div class="col-lg-2">
                                        <i class="fa fa-phone-alt icons text-body"></i>
                                    </div>
                                    <div class="col-lg-10">
                                        <div class="ms-3">

                                            <h6 class="headd"><strong>Call Us</strong></h6>
                                            <a href="tel:+919874563210" style="color:black">
                                                <span>+91 9874563210</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row  pb-5 mb-4">
                                    <div class="col-lg-2">
                                        <i class="fa fa-envelope icons text-body"></i>
                                    </div>
                                    <div class="col-lg-10">
                                        <div class="ms-3">
                                            <h6 class="headd"><strong>Mail Us</strong></h6>
                                            <a href="mailto:info@financier.com" target="_blank" class="text-black">
                                                info&#64;financier.com
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                 
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60888.89771079717!2d78.47973125273693!3d17.48094752523265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b62ca8ad393%3A0xd8ad3231de1dfeb5!2sMallareddy%20Gardens!5e0!3m2!1sen!2sin!4v1717740106389!5m2!1sen!2sin" width="1100" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
