import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { HowitisworksComponent } from 'src/app/Pages/howitisworks/howitisworks.component';
import { AboutUsComponent } from 'src/app/Pages/about-us/about-us.component';
import { ContactUsComponent } from 'src/app/Pages/contact-us/contact-us.component';
import { HomeComponent } from 'src/app/Pages/home/home.component';
import { LoginComponent } from 'src/app/Pages/login/login.component';
import { RegistrationComponent } from 'src/app/Pages/registration/registration.component';
import { SubcriptionComponent } from 'src/app/Pages/subcription/subcription.component';
import { RoleSelectionComponent } from './Pages/role-selection/role-selection.component';
import { NoticeformComponent } from '../app/Pages/noticeform/noticeform.component';
import { PreviewComponent } from './Pages/preview/preview.component';
import { FinacialformPDFComponent } from './components/finacialform-pdf/finacialform-pdf.component';
import { PawanbrokerPDFComponent } from './components/pawanbroker-pdf/pawanbroker-pdf.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { FinalnoticeComponent } from './Pages/finalnotice/finalnotice.component';
import { Noticeform1Component } from './Pages/noticeform1/noticeform1.component';
import { Noticeform2Component } from './Pages/noticeform2/noticeform2.component';
import { Finalnotice2Component } from './Pages/finalnotice2/finalnotice2.component';
import { Noticeform3Component } from './Pages/noticeform3/noticeform3.component';
import { Finalnotice3Component } from './Pages/finalnotice3/finalnotice3.component';
import { Noticeform4Component } from './Pages/noticeform4/noticeform4.component';
import { Finalnotice4Component } from './Pages/finalnotice4/finalnotice4.component';
import { Noticeform5Component } from './Pages/noticeform5/noticeform5.component';
import { Finalnotice5Component } from './Pages/finalnotice5/finalnotice5.component';
import { Noticeform6Component } from './Pages/noticeform6/noticeform6.component';
import { Finalnotice6Component } from './Pages/finalnotice6/finalnotice6.component';
import { Noticeform7Component } from './Pages/noticeform7/noticeform7.component';
import { Finalnotice7Component } from './Pages/finalnotice7/finalnotice7.component';
import { Form35Component } from './Pages/form35/form35.component';
import { Final35Component } from './Pages/final35/final35.component';
import { Form36Component } from './Pages/form36/form36.component';
import { Final36Component } from './Pages/final36/final36.component';
import { SearchrefrenceComponent } from './Pages/searchrefrence/searchrefrence.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
    { path: 'FinacialformPDF', component: FinacialformPDFComponent},
    { path: 'PawanbrokerPDF', component: PawanbrokerPDFComponent},
    { path: 'home-one', component: HomeOneComponent},
    { path: 'home-twelve', component: HomeTwelveComponent},
    { path: 'home-thirteen', component: HomeThirteenComponent},
    { path: 'blog-details', component: BlogDetailsComponent},
    { path: 'About Us', component: AboutUsComponent },
    { path: 'home-two', component: HomeTwoComponent},
    { path: 'home-three', component: HomeThreeComponent},
    { path: 'home-four', component: HomeFourComponent},
    { path: 'home-five', component: HomeFiveComponent},
    { path: 'home-six', component: HomeSixComponent},
    { path: 'home-seven', component: HomeSevenComponent},
    { path: 'home-eight', component: HomeEightComponent},
    { path: 'home-nine', component: HomeNineComponent},
    { path: 'home-ten', component: HomeTenComponent},
    { path: 'home-eleven', component: HomeElevenComponent},
    { path: 'Howitisworks', component: HowitisworksComponent },
    { path: 'AboutUs', component: AboutUsComponent },
    { path: 'ContactUs', component: ContactUsComponent },
    { path: 'Registration', component: RegistrationComponent },
    { path: 'Login', component: LoginComponent },
    { path: 'Subcription', component: SubcriptionComponent },
    { path: 'RoleSelection', component: RoleSelectionComponent },
    { path: 'noticeform', component: NoticeformComponent },
    { path: 'preview', component: PreviewComponent },
    { path: '1home', component: HomepageComponent },
    { path: 'finalnotice', component: FinalnoticeComponent },
    { path: 'noticeform1', component: Noticeform1Component },
    { path: 'noticeform2', component: Noticeform2Component },
    { path: 'finalnotice2', component: Finalnotice2Component },
    { path: 'noticeform3', component: Noticeform3Component },
    { path: 'finalnotice3', component: Finalnotice3Component },
    { path: 'noticeform4', component: Noticeform4Component },
    { path: 'finalnotice4', component: Finalnotice4Component },
    { path: 'noticeform5', component: Noticeform5Component },
    { path: 'finalnotice5', component: Finalnotice5Component },
    { path: 'noticeform6', component: Noticeform6Component },
    { path: 'finalnotice6', component: Finalnotice6Component },
    { path: 'noticeform7', component: Noticeform7Component },
    { path: 'finalnotice7', component: Finalnotice7Component },
    { path: 'form35', component: Form35Component },
    { path: 'final35', component: Final35Component },
    { path: 'form36', component: Form36Component },
    { path: 'final36', component: Final36Component },
    { path: 'searchrefrence', component: SearchrefrenceComponent },


    { path: '**', component: HomeComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
