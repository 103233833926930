<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">

        <a class="navbar-brand" (click)="onCardClick1()">
             <img src="../assets/img/AppLogo.png"   class="navbar-logo">
             <span style="text-transform: capitalize;">Financier Legal App</span>
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <!--<li class="nav-item"><span class="nav-link" routerLink="/contact">Contact Us</span></li>-->

                <li class="nav-item"><span class="nav-link" (click)="onCardClick1()">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/AboutUs">About</span></li>
                <li><span routerLink="/searchrefrence"> sr </span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/Subcription">Subscription</span></li>
                <li class="nav-item"><span class="nav-link" href="#section1">How It Works</span></li>
                <li class="nav-item" *ngIf="LoginDet; else showLogin">
                    <span class="nav-link" routerLink="" style="font-weight: bold; color: brown;">
                        {{ LoginDet[0].Name }}
                    </span>
                </li>

                <li class="nav-item" *ngIf="LoginDet">
                    <span class="nav-link" (click)="logout()">Logout</span>
                </li>
                <ng-template #showLogin>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/Login">Login</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/RoleSelection">Registration</span>
                    </li>
                </ng-template>

                <li class="nav-item"><span class="nav-link" routerLink="/ContactUs">Contact</span></li>
                <li>
                    <input [(ngModel)]="seracingdata" (input)="searchdata()" placeholder="Search" />
                    <button class="close" (click)="clearSearch()">X</button>
                    <div class="card" *ngFor="let item of Searchingusers">
                        {{item.BorrowerName}},&nbsp;{{item.VehicleRegNo}},{{formatStatus(item.VehicleBlocked)}}
                    </div>
                </li>
            

            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/About Us">About</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/Subcription">Subscription</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/About Us">Contact</span></li>
            </ul>
        </div>
    </div>
</nav>
