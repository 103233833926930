<!--2nd Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Final Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Final Notice</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
    <div id="pdf-content">
        <div class="container pb-0 content">

            <div class="row header">
                <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">

                </div>
                <div class="col-sm-4 col-md-4 col-4">
                    <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
                </div>
                <div class="col-sm-4 col-md-4 col-4 ">
                    <div class="middle">
                        <h6> <b>BY WAY OF SPEED POST</b></h6>
                    </div>
                    <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
                </div>
                <div class="col-sm-4 col-md-4 col-4 text-Center right no-gap">
                    <b>
                        <p class="text-black ">VVS. SATYANARAYANA</p>
                        <p class="text-black ">Advocates</p>
                        <p class="text-black ">Legal Consultants & Solicitors</p>
                        <p class="text-black ">Cell No.9381417022</p>
                    </b>
                </div>
            </div>
        </div>
        <div class="double-line-container"></div>
        <div class="container-fluid pt-3 content">
            <div class="container  bottom-border">
                <div class="row no-gap">
                    <div class="col-lg-12   border-top1"></div>
                    <div class="col-12 text-end border-top2">
                        <p>Date: <b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                    </div>
                    <div class="col-lg-4 col-4">
                        <p>To</p>
                        <p><b>{{Fdata[0].BorrowerName}}</b></p>
                        <p><b>{{Fdata[0].BarrowerAddress}}</b></p>
                        <p>(Borrower / Debtor)</p>
                    </div>
                    <div class="col-lg-3 col-4">

                    </div>
                    <div class="col-lg-5 col-4 text-left">
                        <div class="fromadd">
                            <p>To</p>
                            <p><b>{{Fdata[0].SuretyName}}</b></p>
                            <p><b>{{Fdata[0].SuretyAddress}}</b></p>
                            <p>(Surety / Guarantor)</p>
                        </div>
                    </div>
                </div><br />
                <div class="main-content">
                    <h5>Ref:<strong class="text-danger"> {{ rolename }}</strong></h5>
                    <p class="para">
                        Final opportunity to settle outstanding amount and release
                        vehicle Madam/Sir Under instruction from and on behalf of my
                        client M/s <span style="color:red">{{Fdata[0].CompanyName}}</span> a notice has been issued to you on
                        dt: <span style="color:red">{{ Fdata[0].NoticeIssuedDate | date:'dd-MM-yyyy' }}</span> regarding conciliation and dispute resolution. You
                        have declined the notice. Under a few mandatory circumstances
                        your vehicle has been impounded. As of today your total due to
                        my client is <span style="color:red">Rs. {{Fdata[0].DueAmount}}/-</span> In order to recover this arrears, our client is
                        going to impound the vehicle whose registration number is
                        <span style="color:red"> {{ Fdata[0].VehicleRegNo }}</span> This notice is being sent to give you a final
                        opportunity. Within seven days of receiving this notice, kindly
                        clear all the due amount to my client to have your vehicle
                        released. Otherwise the vehicle will be auctioned by
                        arbitrators.Thank you

                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                    </div>
                    <div class="col-lg-4 text-left">
                        <div class="text-center">
                            <p>Yours sincerely,</p><br />
                            <p><b>VVS SATYA NARAYANA</b></p>
                            <p>Advocate</p>
                        </div>
                    </div>

                </div>

            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-left">
                        <h6 class="text-danger boldd p-3">Address to corresponding: {{loginDet[0].CompanyAddress}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].AgreementNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-3">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
    </div>
</div>
