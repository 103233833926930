import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller, } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralserviceService } from '../../../generalservice.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    LoginDet: any;
    LoginDetails: any;
    seracingdata: any;
    Searchingusers:any;

    constructor(private viewportScroller: ViewportScroller, public activeroute: ActivatedRoute,
        public router: Router, public generalservice: GeneralserviceService, public http: HttpClient) {
        debugger
        this.LoginDetails = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails);
        this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }
    logout() {
        localStorage.removeItem('LoginDetails');
        this.router.navigate(['/']);
        location.reload();
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
      // this.searchdata();

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    onCardClick1(): void {
        debugger;
        if (this.LoginDetails !== undefined && this.LoginDetails !== null) {
            this.router.navigate(['/1home']); // Navigate to the navbar page if LoginDetails is defined
        } else {
            this.router.navigate(['/']); // Navigate to /1home if LoginDetails is not defined
        }
    }

    searchdata() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.seracingdata);
        var url = "api/FinancierLaw/Search_Customers_based_vno_name";
        this.generalservice.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.Searchingusers = data;
        })
    }

    formatStatus(isBlocked: boolean): string {
        debugger
        return isBlocked ? 'Active' : 'Blocked'; // Format status
    }

    clearSearch() {
        this.seracingdata = ''; // Clear the input field
        this.Searchingusers = []; // Clear the search results
    }

}
