<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Subscription
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Subscription</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-4">
                <div class="parent-wrapper">
                    <!--<span class="close-btn glyphicon glyphicon-remove"></span>-->
                    <div class="subscribe-wrapper">
                        <img src="../../../assets/img/law1.jpg" />
                        <div class="textt">
                            <h6 class="h66">{{PackageName}}</h6>
                           
                            <h6 *ngIf="PackageDiscount > 0">{{PackageDiscount}}% Discount</h6>
                            <br />
                            <h6 class="mt-2">{{PackageDescription}}</h6>
                           
                            <h6 class="mt-2">{{PaymentMode}}</h6> 
                            <h6 class="mt-2"><b>{{PackageDuration}}</b></h6>

                            <div class="submit-btn">{{PackagePrice}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="parent-wrapper">
                    <!--<span class="close-btn glyphicon glyphicon-remove"></span>-->
                    <div class="subscribe-wrapper">
                        <img src="../../../assets/img/law2.jpg" />
                        <div class="textt">
                            <h6 class="h66">{{PackageName}}</h6>
                            <h6 *ngIf="PackageDiscount > 0">{{PackageDiscount}}% Discount</h6>
                            <br />
                            <h6 class="mt-2">{{PackageDescriptions}}</h6> 

                            <h6 class="mt-2">{{PaymentModes}}</h6> 
                            <h6 class="mt-2"><b>{{PackageDurations}}</b></h6>

                            <div class="submit-btn">{{PackagePrices}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mt-5">
                <img src="../../../assets/img/upi.webp" />
                <p><b>Note:</b>"Please enter your last 10 digit of transactionid for quick verification"  </p>
                <input type="text" #transactionInput name="transactionID" placeholder="Your Transaction ID">
                <button (click)="subscription(transactionInput.value)" style="background-color:#61a111">Submit</button>

            </div>





        </div>
    </div>
</div>
