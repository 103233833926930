import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeerarserviceService {

    private apiURL = "http://localhost:51079/api/Token/Gettoken";
  //  private apiURL = "https://financerlawapp.com/apiv1/api/Token/Gettoken";


    constructor(public http: HttpClient) { }

    getBearerToken(): Observable<any> {
        return this.http.get<any>(`${this.apiURL}`);
    }

}
